﻿@import "ui/ui-variables";

.win-link {
    text-decoration: none !important;
}

.win-code {
    padding: @unit * 1px;
    white-space: pre-wrap;
    word-wrap: break-word;
}


.form-group label {
    margin-top: 10px;
}

.form-group input {
    margin-top: 6px;
    margin-bottom: 6px;
}


.win-stylize {
    font-family: "Segoe UI", sans-serif, "Segoe MDL2 Assets", "Symbols";
}

.win-viewbox {
  width: 100%;
  height: 100%;
  position: relative;
}